<template>
  <div class="home">

    <section>
      <div class="container pt-30 pb-30">
        <div class="row">
          <div cla="col">
            <h1 class="title">Your support packs</h1>
          </div>
        </div>
      </div>
    </section>
    <section
      class="hours-insight"
      style="background-image: url('images/attractor-bg.png')"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div
            class="col-lg-4 col-md-6"
            v-for="project in projectDetails"
            :key="project.id"
          >
            <div class="support-pack-card text-center mb-30">
              <div class="pack-card-image pb-30">
                <img
                  src="images/project_icons/project_icon.png"
                  alt=""
                  srcset=""
                  width="150"
                />
              </div>
              <div class="pack-card-body pb-30">
                <p class="paragraph-large">{{ project.brandName != null ? project.brandName : project.name }}</p>
              </div>
              <div class="wrap-login100-form-btn support-pack-btn">
                <div class="login100-form-bgbtn"></div>
                <button
                  class="login100-form-btn"
                  v-on:click="viewPage(project)"
                >
                  View support pack
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="loader-overlay" v-if="loading == true">
      <Loader> </Loader>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import DataService from "@/api/DataService";
import Store from "@/store";
import Loader from "@/components/loader.vue";
import router from "@/router";
export default {
  name: "Home",
  data() {
    return {
      // 5002000000593107 - lifestyle
      projectIds: [],
      projectDetails: [],
      projectTasks: null,
      openTasks: {},
      closedTasks: {},
      brandName: "",
      latestSupportPack: 0,
      dateInvoiced: "",
      tasks: null,
      hoursRemaining: 0,
      loading: true,
    };
  },
  components: {
    Loader,
   
  },
  created() {
     var isAuthenticated = localStorage.getItem('isAuthenticated');
        if(!(isAuthenticated == 'true')){
            router.push({ name: "Login" });
        }
  },

  mounted() {
    var projects = JSON.parse(localStorage.getItem("projects"));
    Store.state.projects = projects;
    for (var i in projects) {
      this.projectIds[i] = projects[i].project_id;
    }
    Store.state.projectIds = this.projectIds;
    this.getProjectDetails();
  },

  methods: {
    viewPage(project) {
      localStorage.setItem("current_project", JSON.stringify(project));
      router.push({ name: "SingleProject", params: { slug: project.slug } });
    },
    async getProjectDetails() {
      var projects = JSON.parse(localStorage.getItem("projects"));
      var projectDetails = Store.state.allProjectDetails;
      if (projectDetails == null) {
        await DataService.getProjectDetails(this.projectIds).then(
          (response) => {
        
            if (response.status == "200") {
              this.projectDetails = response.data;
              this.loading = false;
              // Embes slugs in project details
              for (var i in this.projectDetails) {
                for (var k in projects) {
                  if (
                    projects[k].project_id == this.projectDetails[i].id_string
                  ) {
                    this.projectDetails[i].slug = projects[k].slug;
                  }
                }
              }
              localStorage.setItem(
                "project_details",
                JSON.stringify(this.projectDetails)
              );
              Store.state.allProjectDetails = this.projectDetails;
              this.updateBrandName();
            }
          }
        );
      } else {
        this.projectDetails = projectDetails;
        this.loading = false;
      }
    },
    updateBrandName() {

      for (var i in this.projectDetails) {
        if ("custom_fields" in this.projectDetails[i]) {
          
          var customFields = this.projectDetails[i].custom_fields;
          customFields.forEach((field) => {
            if (field["Client Brand Name"]) {
      
              this.projectDetails[i].brandName = field["Client Brand Name"];
            }
          });
        } else {
         this.projectDetails[i].brandName = null;
        }
      }
    },
  },
};
</script>

<style>
</style>
